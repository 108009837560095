import { Component, OnInit, HostListener, ApplicationRef, ViewChild, ElementRef } from '@angular/core';
import { Observable, Observer, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ScreenService, Screen } from './screen.service';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '@environment';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isOnline = false;
  screen: Screen;
  screen$: any;
  orientation: ScreenOrientation;
  windowHoverTimeout: any;
  environment: any;
  showPWAEnforcer = false;
  rotation =  {
    landscape: true,
    portrait: false,
    landscapeInverted: false,
    portraitInverted: false
  };
  initMetadataSynced = false;
  navigatorObj: any = window.navigator;
  languages: string[];
  selectedLang: string;
  defaultLang = 'en';

  @ViewChild('screenshot', { static: false, read: ElementRef }) screenshot: ElementRef;
  @ViewChild('canvas', { static: false, read: ElementRef }) canvas: ElementRef;
  @ViewChild('downloadLink', { static: false, read: ElementRef }) downloadLink: ElementRef;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private screenService: ScreenService,
    private appRef: ApplicationRef,
    public updates: SwUpdate,
    private deviceService: DeviceDetectorService
  ) {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const languages = environment.languages;
    this.languages = languages;
    let lang = this.defaultLang;
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(lang);
    let localLang: string;
    let localSet = false;
    // try initialize language from localstorage (user setting preference)
    try {
      localLang = localStorage.getItem('lang');
      if (languages.includes(localLang)) {
        console.log("uselocallang:", localLang)
        translate.use(localLang);
        localSet = true;
      }
    } catch (error) {
      console.log("error translation local", error);
    }
    // if local language not initialized, try initialize from browser language
    if (!localSet) {
      let navLang: string;
      try {
        navLang = this.navigatorObj.language.split('-')[0];
        if (languages.includes(navLang)) {
          console.log("usenavlang:", navLang)
          translate.use(navLang);
        } else {
          console.log("uselang:", lang)
          translate.use(lang);
        }
      } catch (error) {
        console.log("uselang:", lang)
        translate.use(lang);
      }
    }
    this.selectedLang = translate.currentLang;
    
    this.createOnline$().subscribe(isOnline => {
      this.isOnline = isOnline
      screenService.isOnline.next(isOnline);
    });
    this.environment = environment;
    this.orientation = window.screen.orientation; // mobile orientation
    updates.available.subscribe(event => {
      if (event.current !== event.available) {
        updates.activateUpdate().then(() => this.updateApp());
      }
    });

    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everyMinute$ = interval(60000);
    const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);
    // TODO: APP sometimes nevers stabilizes, above not working
    everyMinute$.subscribe(() => {
      updates.checkForUpdate();
    });
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.orientation = window.screen.orientation;
    this.screenService.updateScreenMetadata();
  }

  ngOnInit() {
    const screen = this.screenService.getScreenFromLocalStorage();
    this.setRotation();
    const screenId: string = localStorage.getItem('screenId');
    if (screen && screenId) {
      this.screenService.subscribeToScreen(screenId);
    }
    this.screen$ = this.screenService.screen.subscribe(
      (s) => {
        this.screen = s;
        this.setRotation();
        if (s && s.uuid && !this.initMetadataSynced) {
          this.initMetadataSynced = true;
          this.screenService.updateScreenMetadata();
        }
      }
    );
    const isInStandaloneMode = () =>
      (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator['standalone']) || document.referrer.includes('android-app://');

    if (isInStandaloneMode()) {
      this.showPWAEnforcer = false;
    } else {
      if (this.deviceService.isMobile()) {
        this.showPWAEnforcer = true;
      }
    }
  }

  onSelectedLangChange(e) {
    // Store and use the selected language
    if (this.selectedLang) {
      this.translate.use(this.selectedLang);
      localStorage.setItem('lang', this.selectedLang);
    }
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  clearLocalStorage() {
    if (!confirm('Reset Player?')) { return; }
    localStorage.clear();
    /* this.router.navigateByUrl('/connect'); */
    this.updateApp();
  }

  currentYear() {
    return (new Date()).getFullYear();
  }

  updateApp() {
    document.location.reload();
  }

  setRotation() {
    if (!this.screen || !this.screen.rotation) { return; }
    const orientationType = this.orientation.type; // landscape-primary, portrait-primary
    this.rotation.landscape = (this.screen.rotation.toString() === '0') ||
                              (this.screen.rotation.toString() === '90' && orientationType === 'portrait-primary')
                               ? true : false;
    this.rotation.portrait = (this.screen.rotation.toString() === '90' && orientationType !== 'portrait-primary')
                                 ? true : false;
    this.rotation.landscapeInverted = (this.screen.rotation.toString() === '180') ||
                                      (this.screen.rotation.toString() === '270' && orientationType === 'portrait-primary') ? true : false;
    this.rotation.portraitInverted = this.screen.rotation.toString() === '270' && orientationType !== 'portrait-primary' ? true : false;
  }

  checkIsLandscape() {
    // tslint:disable-next-line: max-line-length
    if ((this.screen.rotation >= 0  && this.screen.rotation < 90 ) || (this.screen.rotation >=  180 && this.screen.rotation < 270) || (this.screen.rotation > 315  && this.screen.rotation === 360)) {
      return true;
    } else {
      return false;
    }
  }

  checkIsPortrait() {
    if ((this.screen.rotation >= 90 && this.screen.rotation < 180)) {
      return true;
    } else {
      return false;
    }
  }

  checkIsPortrait270() {
    if ((this.screen.rotation >= 270 && this.screen.rotation <= 315 )) {
      return true;
    } else {
      return false;
    }
  }

}
