import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { YoojisApiService } from './yoojis-api.service';
import { IaffmpegService } from './iaffmpeg.service';
import { map } from 'rxjs/operators';
import { Timetable } from './models/timetable.class';
import { MensaMaxApiService } from './mensamax-api.service';
import { MensaMaxRootObject, MensaMaxRequestBody } from './models/mensamax.class';
import { ModuleService } from './module.service';
import { FireFunctionsApiService } from './firefunctions.api.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private api: ApiService,
    private yoojisApi: YoojisApiService,
    private iaffmpegService: IaffmpegService,
    private mensaMaxApi: MensaMaxApiService,
    private moduleService: ModuleService,
    private fireFunctionsApi: FireFunctionsApiService
  ) { }

  authenticate() {
    this.moduleService.longpullingTimestamp.next(null);
    this.moduleService.mensaMaxData.next(null);
    this.moduleService.moduleMediaItems.next([]);
    this.moduleService.isRequesting.next(false);
    return this.api.get('/Screens/Frontend/', 'json', true).pipe(
      map(
        (data: Timetable) => {
          localStorage.setItem('timetable', JSON.stringify(data));
          return data;
        }
      )
    );
  }

  getTimetableJson() {
    return this.api.getLocal('/timetable.json', 'json', true).pipe(
      map(
        (data) => {
          return data;
        }
      )
    );
  }

  checkIfYoojisPromoPresent(restaurantId: any) {
    return this.yoojisApi.get('/Promotions/Screenlime/' + restaurantId, 'json', true).pipe(
      map(
        (data) => {
          return data;
        }
      )
    );
  }

  postYoojisMediaIaffmpeg(payload: any) {
    return this.iaffmpegService.post('/yoojisiaffmpeg', payload, 'json', true).pipe(
      map(
        (data) => {
          return data;
        }
      )
    );
  }

  getMensaMaxMenuPHP(payload: MensaMaxRequestBody) {
    return this.mensaMaxApi.post('/mensamax/mensamax-json.php', payload, 'json', false).pipe(
      map(
        (data: MensaMaxRootObject) => {
          return data;
        }
      )
    );
  } 
  
  getMensaMaxMenu(payload: MensaMaxRequestBody) {
    return this.fireFunctionsApi.get('mensamax/'+payload.MenueplanID, 'json', false).pipe(
      map(
        (data: MensaMaxRootObject) => {
          return data;
        }
      )
    );
  }
}
