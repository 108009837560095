import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ScreenService } from '../screen.service';
import { retry } from 'rxjs/operators';

@Injectable()
export class IaffmpegService {
  public bearerToken: string;
  public currentUser: any;
  loading = false;

  constructor(
    private http: HttpClient,
    private screenService: ScreenService
  ) { }

  setHeaders(headerType, authenticate) {
    const headersConf = {};
    if (headerType === 'json') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'json-x') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'form') {
      headersConf['Content-Type'] = 'application/x-www-form-urlencoded';
    } else if (headerType === 'multipart') {
      headersConf['Content-Type'] = 'multipart/form-data';
    }
    // Authkey
    // Authorization
    if (authenticate) {
      const screen = this.screenService.getScreenFromLocalStorage();
    }
    return new HttpHeaders(headersConf);
  }

  /*get(path: string, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.get(environment.iaffmpegBaseUrl + path, { headers });
  }

  getLocal(path: string, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.get(path, { headers });
  }

  patch(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.patch(environment.yoojisBaseUrl + path, body, { headers });
  }*/

  post(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.post(environment.iaffmpegBaseUrl + path, body, { headers }).pipe(retry(2));
  }

  /*put(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.put(environment.yoojisBaseUrl + path, body, { headers });
  }

  delete(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.delete(environment.yoojisBaseUrl + path, { headers });
  }*/

}
