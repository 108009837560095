import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ModuleService } from 'src/app/player-v2/module.service';
import { MensaMaxRootObject, Tagesspeiseplaene, MenuImage } from 'src/app/player-v2/models/mensamax.class';
import * as moment from 'moment';
import { Media } from 'src/app/player-v2/models/media.class';

@Component({
  selector: 'app-mensamax',
  templateUrl: './mensamax.component.html',
  styleUrls: ['./mensamax.component.scss']
})
export class MensamaxComponent implements OnInit, AfterViewInit, AfterViewChecked {

  data: MensaMaxRootObject;
  data$: any;
  dayMenu: Tagesspeiseplaene;
  images: MenuImage[];
  imageVersion: number;
  imageHeights = [0, 0];



  @ViewChildren('dayMenuDivs') dayMenuDivs: QueryList<ElementRef>;

  constructor(
    private moduleService: ModuleService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.imageVersion = (new Date()).getTime();
    this.data = this.moduleService.mensaMaxData.value;
    this.setDayMenu();
    this.moduleService.mensaMaxData.subscribe((data) => {
      if (!data) {return; }
      this.data = data;
      this.images = data.images;
      this.setDayMenu();
    });  
  }

  ngAfterViewInit() {
    // this.dayMenuDivs.forEach((d, index) => {
    //   let takenHeight = 0;
    //   takenHeight += d.nativeElement.children[0].offsetHeight;
    //   takenHeight += d.nativeElement.children[1].offsetHeight;
    //   takenHeight += d.nativeElement.children[2].offsetHeight;
    //   const avail = d.nativeElement.offsetHeight - takenHeight - 200;
    //   this.imageHeights[index] = avail;
    // });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  setDayMenu() {

    const today = moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss');
    this.dayMenu = this.data.menu.body.Wochenspeiseplaene[0].Tagesspeiseplaene.find((t) => t.Datum === today);
  }

  hasDecimal(n: number) {
    return (n - Math.floor(n)) !== 0;
  }

  mealplans() {
    const dummy = [];
    return [this.dayMenu.TagesspeiseplanPositionen[0], this.dayMenu.TagesspeiseplanPositionen[1]];
  }

  getV() {
    return '&v=' + new Date().getTime();
  }

}
