export const environment = {
    production: true,
    identifier: 'dev',
    commitNumber: 0,
    languages: ['en', 'de', 'fr', 'it', 'es'],
    screenManager: {
        baseUrl: 'https://manager-dev.screenlime.app/#/',
        connectPath: 'connect/',
        screenPath: 'screen/'
    },
    api_url: 'https://iascreenmanager.azurewebsites.net/api',
    azureCDN: 'https://media.screenlime.ch/',
    firebase: {
        apiKey: 'AIzaSyCkDEY2bJ9Zrwyv6zrDfLBMphzj8bobgpU',
        authDomain: 'screenlime-dev.firebaseapp.com',
        databaseURL: 'https://screenlime-dev.firebaseio.com',
        projectId: 'screenlime-dev',
        storageBucket: 'screenlime-dev.appspot.com',
        messagingSenderId: '222484523454',
        appId: '1:222484523454:web:d098668b0d370785e571ef',
        measurementId: 'G-Y3PMKWT0YL'
    },
    yoojisBaseUrl: 'https://yoo3.azurewebsites.net/api',
    iaffmpegBaseUrl: 'https://ffmpegpublisher.inaffect.one',
    mensaMaxBaseUrl: 'https://web2020.screenlime.ch',
    firestore_api_url: 'https://firestore.googleapis.com/v1/projects/screenlime-dev/databases/(default)/documents/',
    firefunction_api_url: 'https://us-central1-screenlime-dev.cloudfunctions.net/api/'
};
