import {Component, OnInit, OnDestroy} from '@angular/core';
import {RequestService} from './request.service';
import {Timetable} from './models/timetable.class';
import {Media} from './models/media.class';
import {MediaItem} from './models/media-item.class';
import {ScreenService, Screen} from '../screen.service';
import {Observable, Observer, fromEvent, merge} from 'rxjs';
import {map} from 'rxjs/operators';
import { Router } from '@angular/router';
import {SpecialPriorityStrategy} from './strategies/special-priority.strategy';
import {PriorityStrategy} from './strategies/priority-strategy.abstract';
import {MainPriorityStrategy} from './strategies/main-priority.strategy';
import { environment } from '@environment';
import {WeekPriorityStrategy} from './strategies/week-priority.strategy';
import { timer } from 'rxjs';
import { interval } from 'rxjs';
import { ModulePriorityStrategy } from './strategies/module-priority.strategy';
import { ModuleService } from './module.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-player-v2',
  templateUrl: './player-v2.component.html',
  styleUrls: ['./player-v2.component.scss']
})
export class PlayerV2Component implements OnInit, OnDestroy {
  authkey = 'LXFPKQ';
  timetable: Timetable;
  priorities: PriorityStrategy[];
  playlistInterval: any;
  sliderInterval: any;
  currentMedia: Media;
  useLocalSrc = false;
  sliderIndex = 0;
  screen$: any;
  screen: Screen;
  isOnline = false;
  restart = false;
  loading = {
    timetable: false
  };
  environment: any;

  constructor(
    private request: RequestService,
    private screenService: ScreenService,
    private router: Router,
    private moduleService: ModuleService
  ) {
    this.priorities = [
      new ModulePriorityStrategy(this.request, moduleService),
      new SpecialPriorityStrategy(this.request, moduleService),
      new WeekPriorityStrategy(this.request, moduleService),
      new MainPriorityStrategy(this.request, moduleService)
    ];
    this.environment = environment;
  }

  ngOnInit() {
    this.createOnline$().subscribe(isOnline => this.isOnline = isOnline);
    // flutter webview localhost port
    if (window.location.port === '4048') {
      this.useLocalSrc = true;
    }

    this.getTimetable();

    // handle update of screen, events
    this.screen$ = this.screenService.screen.subscribe((screen) => {
      const oldTimetableChange = this.screen ? this.screen.timetableChangeOn : null;
      if (JSON.stringify(this.screen) !== JSON.stringify(screen)) {
        if (this.isOnline && screen.timetableChangeOn && screen.timetableChangeOn !== oldTimetableChange) {
          // update timetable
          this.currentMedia = null;
          this.updateTimetable();
        }
      }

      this.screen = screen;
      if (!screen.connectedOn) {
        this.router.navigateByUrl('/connect');
      }
    });
  }

  ngOnDestroy() {
    this.screen$.unsubscribe();
  }

  updateTimetable() {
    this.loading.timetable = true;
    this.getTimetableAndStartPlaylist();
  }

  getTimetable() {
    if (this.isOnline) {
      this.getTimetableAndStartPlaylist();
      return;
    }

    if (this.useLocalSrc) {
      this.getTimetableAndStartPlaylist();
      return;
    }

    const localTimeTable = localStorage.getItem('timetable');
    if (localTimeTable) {
      this.timetable = JSON.parse(localTimeTable);
      this.startPlaylist();
    }
  }

  getTimetableAndStartPlaylist() {
    this.loading.timetable = true;
    this.request.authenticate().subscribe(
      (res: Timetable) => {
        this.timetable = res;
        this.startPlaylist();
      }, () => {
        this.timetable = null;
        this.loading.timetable = false;
      }
    );
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  startPlaylist() {
    this.loading.timetable = true;

    if (this.playlistInterval) {
      this.playlistInterval.unsubscribe();
    }

    const interval1 = interval(1000);
    this.playlistInterval = interval1.subscribe((val) => {
      this.findCurrentPlaylistItem();
      this.loading.timetable = false;
    });
  }

  findCurrentPlaylistItem() {
    this.priorities.some(priorityStrategy => {
      priorityStrategy.setTimeTable(this.timetable);
      const media = priorityStrategy.can() ? priorityStrategy.processing() : null;

      if (media) {
        // Yoojis module
        if (media.mediaTypeId === 'b5911ab1-6e5d-44ac-a25e-7c401bea834f') {
          media.mediaItems = this.moduleService.moduleMediaItems.value;
        }
        this.setCurrentMedia(media);
        return true;
      }

      return false;
    });
  }

  setCurrentMedia(media: Media) {
    if (this.currentMedia) {
      if (media.mediaId !== this.currentMedia.mediaId) {
        this.restart = true;
        this.currentMedia = null;
        const timer1 = timer(100);
        timer1.subscribe(() => this.restart = false);
      }
    }
    this.currentMedia = media;
  }

  startSlider(mediaItems: MediaItem[]) {
    const interval1 = interval(mediaItems[0].showSeconds * 1000);
    this.sliderInterval = interval1.subscribe((val) => {
      if (this.sliderIndex < mediaItems.length) {
        this.sliderIndex++;
      } else {
        this.sliderIndex = 0;
      }
    });
  }

  stopSlider() {
    if (this.sliderInterval) {
      this.sliderInterval.unsubscribe();
    }
  }

  onPlayerEnded(event) { }
}
