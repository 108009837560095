import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConnectComponent } from './connect/connect.component';
import { PlayerV2Component } from './player-v2/player-v2.component';
import { NativeTriggerComponent } from './native-trigger/native-trigger.component';
import { ConnectGuardService } from './connect/connect.guard';

const e = localStorage.getItem('entryUrl');

const routes: Routes = [
  { path: '', redirectTo: '/connect', pathMatch: 'full' },
  {
    path: 'connect', component: ConnectComponent, canActivate: [ConnectGuardService],
  },
  {
    path: 'connect/:reconnectUUID', component: ConnectComponent,
  }, 
  {
    path: 'player-v2', component: PlayerV2Component
  },
  {
    path: 'native-trigger/:trigger', component: NativeTriggerComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
