import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
declare let navigator: any;
declare let MediaRecorder: any;

@Component({
  selector: 'app-screen-capture',
  templateUrl: './screen-capture.component.html',
  styleUrls: ['./screen-capture.component.scss']
})
export class ScreenCaptureComponent implements OnInit {

  enableStartCapture = true;
  enableStopCapture = false;
  enableDownloadRecording = false;
  stream = null;
  chunks = [];
  mediaRecorder = null;
  status = 'Inactive';
  recording = null;

  constructor(private storage: AngularFireStorage) { }

  ngOnInit() {
  }

  _startScreenCapture() {
    if (navigator.getDisplayMedia) {
      return navigator.getDisplayMedia({video: true});
    } else if (navigator.mediaDevices.getDisplayMedia) {
      return navigator.mediaDevices.getDisplayMedia({video: true});
    } else {
      return navigator.mediaDevices.getUserMedia({video: {mediaSource: 'screen'}});
    }
  }



  async _startCapturing(e) {
    console.log('Start capturing.');
    this.status = 'Screen recording started.';
    this.enableStartCapture = false;
    this.enableStopCapture = true;
    this.enableDownloadRecording = false;


    if (this.recording) {
      window.URL.revokeObjectURL(this.recording);
    }

    this.chunks = [];
    this.recording = null;
    this.stream = await this._startScreenCapture();
    this.stream.addEventListener('inactive', e => {
      console.log('Capture stream inactive - stop recording!');
      // this._stopCapturing(e);
    });
    this.mediaRecorder = new MediaRecorder(this.stream, {mimeType: 'video/webm'});
    this.mediaRecorder.addEventListener('dataavailable', event => {
      if (event.data && event.data.size > 0) {
        this.chunks.push(event.data);
      }
    });
    this.mediaRecorder.start(10);
  }

  _stopCapturing(e) {
    console.log('Stop capturing.');
    try {
      this.status = 'Screen recorded completed.';
    this.enableStartCapture = true;
    this.enableStopCapture = false;
    this.enableDownloadRecording = true;

    this.mediaRecorder.stop();
    this.mediaRecorder = null;
    this.stream.getTracks().forEach(track => track.stop());
    this.stream = null;

    const blob = new Blob(this.chunks, {type: 'video/webm'})
    this.recording = window.URL.createObjectURL(blob);

    this.uploadRecording(blob);
    // window.open(this.recording);
    } catch (error) {
      console.log(error)
    }
    
  }

  uploadRecording(blob: Blob) {
    const filePath = 'screen-captures/' + localStorage.getItem('screenId');
    const ref = this.storage.ref(filePath);
    const task = ref.put(blob);
  }

  // _downloadRecording(e) {
  //   console.log('Download recording.');
  //   this.enableStartCapture = true;
  //   this.enableStopCapture = false;
  //   this.enableDownloadRecording = false;

  //   const downloadLink = this.shadowRoot.querySelector('a#downloadLink');
  //   downloadLink.addEventListener('progress', e => console.log(e));
  //   downloadLink.href = this.recording;
  //   downloadLink.download = 'screen-recording.webm';
  //   downloadLink.click();
  // }

}
