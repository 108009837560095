import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import * as moment from 'moment';


export class Screen {
  uuid: string;
  authKey: string;
  connectedOn: string;
  name: string;
  rotation: number;
  orientationType: any;
  orientationAngle: any;
  innerWidth: any;
  innerHeight: any;
  mediaChangeOn: string;
  timetableChangeOn: string;
  lastSeen: any;
  locationId?: any;
}

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  screen = new BehaviorSubject<Screen>(new Screen());
  screenId = new BehaviorSubject<string>(null);
  loading = new BehaviorSubject<any>(null);
  error = new BehaviorSubject<any>(null);
  item: any;
  itemDoc: any;
  uuid = new BehaviorSubject<string>(null);
  isOnline = new BehaviorSubject<boolean>(false);

  constructor(
    private firestore: AngularFirestore,
    private analytics: AngularFireAnalytics
  ) {

   }

   makeUUIDScreen() {
     const uuid = this.uuid.value;
     this.firestore.collection('screens').doc(uuid).get().subscribe(
       (res) => {
         if (res.exists) {
          this.analytics.logEvent('player_create_screen_existsalready', { uuid });
          this.uuid.next(this.generateUUID());
          this.makeUUIDScreen();
         } else {
           this.createScreen();
         }
       }
     );
   }



  generateUUID(): string {
    const isString = `${this.S4()}-${this.S4()}-${this.S4()}`;

    return isString;
  }

  private S4(): string {
    // tslint:disable-next-line: no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1).toUpperCase();
  }

  createScreen() {
    const uuid = this.uuid.value;
    const s = {
      orientationType: window.screen.orientation.type,
      orientationAngle: window.screen.orientation.angle,
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      lastSeen: moment().format(),
      uuid,
      connectedOn: null
    };

    this.firestore.collection('screens').doc(uuid).set(s)
      .then((docRef) => {
        this.analytics.logEvent('player_create_screen_success', { uuid });
        this.loading.next(null);
        const screenId = this.uuid.value;
        if (screenId) {
          localStorage.setItem('screenId', screenId);
          this.subscribeToScreen(screenId);
        }
      })
      .catch((error) => {
        this.analytics.logEvent('player_create_screen_error', { error });
        this.loading.next(null);
        this.error.next(error);
      });
  }

  updateLocalScreenData(screen) {
    if (screen) {
      this.screen.next(screen);
      localStorage.setItem('screen', screen);
    }
  }

  subscribeToScreen(id) {
    this.screenId.next(id);
    this.itemDoc = this.firestore.doc<any>('screens/' + id);
    this.item = this.itemDoc.valueChanges().subscribe((datas) => {
      if(datas) {
        localStorage.setItem('screen', JSON.stringify(datas));
        this.screen.next(datas);
        this.analytics.logEvent('player_read_screen_success', {uuid: id});
      }
      // // Send message to flutter
      // if ((window as any).FirebaseScreen) {
      //   (window as any).FirebaseScreen.postMessage(JSON.stringify(datas));
      // } else {
      //   console.log(':: ERROR :: Flutter Javascript Message Push channel not found');
      // }
    }, (err) => {
      this.analytics.logEvent('player_read_screen_error', { uuid: id, error: err });
      console.log('error on firebase screen subscription : ', err);
    });

  }

  readScreens() {
    return this.firestore.collection('screens').snapshotChanges();
  }

  updateScreenMetadata() {
    const uuid = this.screen.value.uuid;
    if (!uuid) { return; }
    const screen = this.screen.value;
    screen.orientationType = window.screen.orientation.type;
    screen.orientationAngle = window.screen.orientation.angle;
    screen.innerWidth = window.innerWidth;
    screen.innerHeight = window.innerHeight;
    screen.lastSeen = moment().format();
    this.analytics.logEvent('player_write_screen_metadata', { uuid });
    this.firestore.doc('screens/' + uuid).update(screen);
  }

  getFirestoreScreen(id) {
    return this.firestore.collection('screens').doc(id).get();
  }

  updateScreen(recordId, record) {
    this.firestore.doc('screens/' + recordId).update(record);
  }

  getScreenFromLocalStorage(): Screen {
    const screenString: string = localStorage.getItem('screen');
    const screen: Screen = screenString ? screenString !== 'undefined' ? JSON.parse(screenString) : null : null;
    return screen;
  }
}
