import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConnectComponent } from './connect/connect.component';
import { PlayerV2Component } from './player-v2/player-v2.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ApiService } from './player-v2/api.service';
import { RequestService } from './player-v2/request.service';
import { NativeTriggerComponent } from './native-trigger/native-trigger.component';
import { ConnectGuardService } from './connect/connect.guard';
import { SliderComponent } from './slider/slider.component';
import { VideoComponent } from './video/video.component';
import { MatVideoModule } from 'mat-video';
import { QRCodeModule } from 'angular2-qrcode';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FullscreenTogglerComponent } from './fullscreen-toggler/fullscreen-toggler.component';
import { YoojisApiService } from './player-v2/yoojis-api.service';
import { IaffmpegService } from './player-v2/iaffmpeg.service';
import { ModuleService } from './player-v2/module.service';
import { MensaMaxApiService } from './player-v2/mensamax-api.service';
import { MensamaxComponent } from './module/mensamax/mensamax.component';
import { CurrencyNumberPipe } from './currency-number.pipe';
import { FireFunctionsApiService } from './player-v2/firefunctions.api.service';
import { ScreenCaptureComponent } from './screen-capture/screen-capture.component';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import localeDeCh from '@angular/common/locales/de-CH';
import localeEnGb from '@angular/common/locales/en-GB';
import localeFrCh from '@angular/common/locales/fr-CH';
import localeItCh from '@angular/common/locales/it-CH';
import { registerLocaleData, LOCATION_INITIALIZED } from '@angular/common';
import { PersonCounterComponent } from './module/person-counter/person-counter.component';

registerLocaleData(localeDeCh);
registerLocaleData(localeEnGb);
registerLocaleData(localeFrCh);
registerLocaleData(localeItCh);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

// wait with app start until 18n stuff has been loaded (enables translate.instant())
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en-GB';
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}


@NgModule({
  declarations: [
    AppComponent,
    ConnectComponent,
    PlayerV2Component,
    NativeTriggerComponent,
    SliderComponent,
    VideoComponent,
    FullscreenTogglerComponent,
    MensamaxComponent,
    CurrencyNumberPipe,
    ScreenCaptureComponent,
    PersonCounterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: false}), // false for firestore request analysis
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    MatVideoModule,
    QRCodeModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerWithDelay' }),
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSnackBarModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [
        // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFactory,
    //   deps: [TranslateService, Injector],
    //   multi: true
    // },
    ApiService,
    RequestService,
    ConnectGuardService,
    YoojisApiService,
    IaffmpegService,
    FireFunctionsApiService,
    MensaMaxApiService,
    ModuleService,
    CurrencyNumberPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
