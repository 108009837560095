import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '../../environments/environment';
import { VgAPI } from 'videogular2/compiled/core';
import { MediaItem } from '../player-v2/models/media-item.class';
import { interval } from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ScreenService } from '../screen.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy, OnChanges {

  video: HTMLVideoElement;
  azureCDN = environment.azureCDN;
  env = environment.identifier;
  CDNUrl: string;

  // Videogular API
  api: VgAPI;
  api$: any;
  api2$: any;
  api3$: any;
  api4$: any;
  api5$: any;
  api6$: any;  
  api7$: any;
  api8$: any;
  api9$: any;
  api10$: any;
  
  firstReady = true; // flag set when first ready event is triggered
  videoError = null;
  touchTheVideo = true;
  isPlaying = false;
  interval1: any;


  @Input() mediaItem: MediaItem;
  @Input() useLocalSrc: boolean;
  @Input() loop: boolean;
  @Input() startAnimationDuration: number;
  @Input() endAnimationDuration: number;
  @Input() isActive: boolean;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onPlayerEnded: EventEmitter<any> = new EventEmitter();
  @Output() onPlayerEndSoon: EventEmitter<any> = new EventEmitter();


  constructor(
    private analytics: AngularFireAnalytics,
    private screenService: ScreenService
  ) {
    this.videoError = null;
   }

  ngOnInit() {
    this.CDNUrl = this.getCDNUrl(this.mediaItem.publicUrl);
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes['isActive']) {
          // Watch if player changes from to isActive (next slide)
          this.videoError = null;
          console.log("change isActive", this.isActive, this.api)
          if (this.isActive && this.api) {
            // if video has error skip slide
            if (this.videoError) {
              this.onPlayerEndSoon.emit(true);
              this.onPlayerEnded.emit(true);
            // else if video can play
            } else {  
              // start playing the video 
              console.log("start play")
              this.api.volume = 0; // musthave, else chrome asks for user interaction to autoplay a video
              this.api.seekTime(0);        
              this.api.play();
              this.makeSurePlaying();
            }
          } 
          // If this player is within an inactive slide, reset its states
          if (!this.isActive && this.api) {
            this.api.pause();
            this.api.seekTime(0);
          }
      }
  }

  ngOnDestroy() {
    if (this.api$) {
      this.api$.unsubscribe();
    }
    if (this.api2$) {
      this.api2$.unsubscribe();
    }      
    if (this.api3$) {
      this.api3$.unsubscribe();
    }
    if (this.api4$) {
      this.api4$.unsubscribe();
    }      
    if (this.api5$) {
      this.api5$.unsubscribe();
    }   
    if (this.api6$) {
      this.api6$.unsubscribe();
    }   
    if (this.api7$) {
      this.api7$.unsubscribe();
    }
    if (this.api8$) {
      this.api8$.unsubscribe();
    }      
    if (this.api9$) {
      this.api9$.unsubscribe();
    }   
    if (this.api10$) {
      this.api10$.unsubscribe();
    }
  }

  onPlayerReady(api: VgAPI) {
    console.log("player ready")
    // VG Player API is ready at this point
    if (!this.firstReady) { return }
    this.firstReady = false;
    this.api = api;
    // If this slide is active start playing
    if (this.isActive) {
      console.log("start play")
      this.api.volume = 0; // musthave, else chrome asks for user interaction to autoplay a video
      this.api.seekTime(0);        
      this.api.play();
      this.makeSurePlaying();
      // else reset video to time zero
    } else {
      this.api.pause();
      this.api.seekTime(0);
    }
    // watch for video ended event
    this.api$ = this.api.getDefaultMedia().subscriptions.ended.subscribe(
      () => {
        // set component flag
        this.isPlaying = false;
        // emit ended event
        this.onPlayerEnded.emit(true);
      }
    );     
    // watch for timeupdate change - 230ms event (for leave animation)
    this.api2$ = this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe(
      (d) => {
        const current =  d.srcElement.currentTime * 1000;
        const duration = d.srcElement.duration * 1000;
        // check if 230ms before ended event (for leave animation)
        if (duration - this.endAnimationDuration  <= current) {
        // emit end soon event
        this.onPlayerEndSoon.emit(true);
        }
      }
    );    
    // watch for download progress
    this.api3$ = this.api.getDefaultMedia().subscriptions.progress.subscribe(
      (d) => {
        console.log("video download progress: ", d)
      }
    );   
    // watch for browser stalled event: Fired when the browser is trying to get media data but the data is not available.
    this.api4$ = this.api.getDefaultMedia().subscriptions.stalled.subscribe(
      (d) => {
        this.analytics.logEvent('player_stalled_error', { uuid: this.screenService.uuid.value, error: d });
        console.log("video stalled: ", d)
        this.onPlayerEndSoon.emit(true);
        this.onPlayerEnded.emit(true);
      }
    );    
    // watch for playing event
    this.api5$ = this.api.getDefaultMedia().subscriptions.playing.subscribe(
      (d) => {
        this.isPlaying = true;
        this.touchTheVideo = false;
        this.videoError = false;
        console.log("video playing: ", d)
      }
    );   
     // watch for error event
    this.api6$ = this.api.getDefaultMedia().subscriptions.error.subscribe(
      (d) => {
        this.analytics.logEvent('player_video_error', { uuid: this.screenService.uuid.value, error: d });
        console.log("video error: ", d);
        this.videoError = event;
        this.onPlayerEndSoon.emit(true);
        this.onPlayerEnded.emit(true);
      }
    );    
     // watch for load start event (fired when browser begins loading a video)
    this.api7$ = this.api.getDefaultMedia().subscriptions.loadStart.subscribe(
      (d) => {
        console.log("video loadStart: ", d)
      }
    );    
     // watch for loaded meta data event
    this.api8$ = this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(
      (d) => {
        console.log("video loadedMetaData: ", d)
      }
    );     
    // watch for abort event
    this.api9$ = this.api.getDefaultMedia().subscriptions.abort.subscribe(
      (d) => {
        console.log("video abort: ", d)
      }
    );    
    // watch for play event
    this.api10$ = this.api.getDefaultMedia().subscriptions.play.subscribe(
      (d) => {
        this.isPlaying = true;
        console.log("video play: ", d)
      }
    );
    // watch for video player errors (useless infos returned)
   // this.api2$ = this.api.fsAPI.onerror

  }

  makeSurePlaying() {
    if (!this.api || this.isPlaying) {
      if (this.interval1) {
        this.interval1.unsubscribe();
      }
      return;
    }

    let retries = 0;

    // hotfix, because .play() sometimes does not work
    const interval1 = interval(500);
    this.interval1 = interval1.subscribe((val) => {
      console.log("make sure playing tick")
      if (this.isPlaying && this.interval1) {
        this.interval1.unsubscribe();
        return;
      }
      retries += 1;
      if (retries === 3) {
        // Kill the slide, tell user to touch the video
        this.touchTheVideo = true;
        this.onPlayerEndSoon.emit(true);
        this.onPlayerEnded.emit(true);
        this.interval1.unsubscribe();
        return;
      }
      console.log(this.api.getDefaultMedia())
      
      this.api.volume = 0; // <- set volume to zero
      this.api.play();
    });
  }

  // catch video source errors like 404
  onVideoError(event) {
    this.videoError = event;
    console.log("video error event, ", event);
    this.analytics.logEvent('player_video_error_2', { uuid: this.screenService.uuid.value, error: event });
  }

  getCDNUrl(publicUrl) {
    // we dont have the companyId: https:/....net/[[companyId]]/[[fileId]]
    return publicUrl.replace('https://iascreenmanager.blob.core.windows.net/', environment.azureCDN);
  }

}
