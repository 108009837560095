import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { timer, interval } from 'rxjs';
import { AnimationBuilder, style, animate, AnimationPlayer } from '@angular/animations';
import { MediaItem } from '../player-v2/models/media-item.class';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnDestroy, AfterViewInit {
  sliderInterval: any;
  sliderIndex = 0;
  nextSliderIndex = 0;
  state = 'in';
  azureCDN = environment.azureCDN;
  videoMediaItems: MediaItem[];
  activeSlide = null;
  showNextSlide = false;
  private animationPlayer: AnimationPlayer;
  position = 0;
  endSoonSent = false;
  animationDuration = 1500; // ms
  CDNUrls: Array<string> = [];
  currentMediaItems: MediaItem[];

  @Input() mediaItems: MediaItem[];
  @Input() startMedia: MediaItem;
  @Input() speed: number;
  @Input() useLocalSrc: boolean;
  // @Input() animationDuration: number; // TODO variable from manager

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @ViewChild('slider', { static: false }) slider: ElementRef;

  constructor(
    private animationBuilder: AnimationBuilder
  ) { }

  ngOnInit() {
    this.mediaItems.forEach((m) => {
     this.CDNUrls.push(this.getCDNUrl(m.publicUrl));
    });
    // Because the slider displays all slides side by side to enable the slide animations,
    // we need to build each slide one by one, so that the network is not fully occupied by all slides on initializatiion
    this.currentMediaItems = [];
    this.currentMediaItems.push(this.mediaItems[0]);
    // At the start we start the Slider Interval
    this.startSlider(this.currentMediaItems);
  }

  ngOnDestroy() {
    // When Slider Component is destroyed make sure all slider intervals are killed
    this.stopSlider();
  }

  ngAfterViewInit() {
    // As soon as the view is ready, we play the init Slide animation
    this.makeSliderAnimation(true);
  }

  getNextSliderIndex() {
    let idx = 0;
    if (this.currentMediaItems[this.sliderIndex + 1]) {
      idx = this.sliderIndex + 1;
    } else {
      idx = 0;
    }
    return idx;
  }

  startSlider(mediaItems: MediaItem[], origin?) {
    this.stopSlider();
    const fileExt = this.returnFileExt(mediaItems[this.sliderIndex].filename);
    if (fileExt === 'mp4') {
      // pause slider
      this.makeVideoItems(mediaItems);
      this.stopSlider();
      return;
    }
    const o = origin;
    if (o === 'video') {
      // stop slider and immediately show slide
      this.proceedSlide(mediaItems, o);
    }
    const interval1 = interval(mediaItems[0].showSeconds * 1000);
    this.sliderInterval = interval1.subscribe((val) => {
      this.proceedSlide(mediaItems);
    });
  }

  proceedSlide(mediaItems, o?) {
    if (this.returnFileExt(mediaItems[this.sliderIndex].filename) === 'mp4') {
      // pause slider
      this.makeVideoItems(mediaItems);
      this.stopSlider();
      return;
    }
    if (mediaItems.length - 1 === 0) {
      this.stopSlider();
      this.sliderIndex = 0;

      return;
    }
    if (o !== 'video') {
      if (this.sliderIndex < mediaItems.length - 1) {
        this.sliderIndex += 1;
      } else {
        this.sliderIndex = 0;
      }
    } else {
      o = null;
    }
    if (this.returnFileExt(this.currentMediaItems[this.sliderIndex].filename) === 'mp4') {
      // if next file slide is video we will trigger out animation at video end
      // pause slider
      this.stopSlider();
      this.makeVideoItems(mediaItems);
    } else {
      this.setSlideEndAnimation();
    }
  }

  setSlideEndAnimation(next?) {
    const timer2 = timer(this.currentMediaItems[0].showSeconds * 1000 - this.animationDuration); // apply out animation 300 ms before slide end
    const timer2$ = timer2.subscribe((val) => {
      this.pushNextSlide();
      this.makeSliderAnimation(null, 'next');
    });
  }


  makeSliderAnimation(init?, type?) {
    if (this.animationPlayer) {
      this.animationPlayer.destroy();
    }

    // p = negative percentage of active or next slide (type) to total slides (current media items)
    const p = (100 / this.currentMediaItems.length) * (type === 'next' ? this.getNextSliderIndex() : this.sliderIndex) * -1;
    console.log(p);
    console.log(type);
    console.log(this.position);

    // animation factory
    let factory;
    if (init) {
      factory = this.animationBuilder.build([
        style({ width: this.currentMediaItems.length * 100 + '%', transform: 'translateX(300%)' }),
        animate(this.animationDuration + 'ms cubic-bezier(.35, 0, .25, 1)', style({ transform: 'translateX(0%)' }))
      ]);
    } else if (this.getNextSliderIndex() === 0 && type === 'next') {
      // hack for infinite looping effect of slider
      factory = this.animationBuilder.build([
        style({ width: this.currentMediaItems.length * 100 + '%', transform: 'translateX(' + this.position + '%)' }),
        animate(this.animationDuration / 2 + 'ms cubic-bezier(.35, 0, .25, 1)', style({ transform: 'translateX(-100%)' }))
      ]);
     }  else {
      factory = this.animationBuilder.build([
        style({ width: this.currentMediaItems.length * 100 + '%', transform: 'translateX(' + this.position + '%)' }),
        animate(this.animationDuration + 'ms cubic-bezier(.35, 0, .25, 1)', style({ transform: 'translateX(' + p.toString() + '%)' }))
      ]);
    }
    this.animationPlayer = factory.create(this.slider.nativeElement);
    this.animationPlayer.play();

     // hack for infinite looping effect of slider
    if (this.getNextSliderIndex() === 0 && type === 'next') {
      setTimeout(() => {
        factory = this.animationBuilder.build([
          style({ width: this.currentMediaItems.length * 100 + '%', transform: 'translateX('+ (100/this.currentMediaItems.length) + '%)' }),
          animate(this.animationDuration / 2 + 'ms cubic-bezier(.35, 0, .25, 1)', style({ transform: 'translateX(0%)' }))
        ]);
        this.animationPlayer = factory.create(this.slider.nativeElement);
        this.animationPlayer.play();
      }, this.animationDuration / 4);
    }


    this.position = p;

    if (init && this.returnFileExt(this.currentMediaItems[this.sliderIndex].filename) !== 'mp4') {
      this.setSlideEndAnimation('next');
    }
  }

  makeVideoItems(mediaItems) {
    this.endSoonSent = false;
    const videoItems = [];
    this.videoMediaItems = null;
    videoItems.push(mediaItems[this.sliderIndex]);
    setTimeout(() => {
      this.videoMediaItems = videoItems;
    }, 0);
  }

  stopSlider() {
    if (this.sliderInterval) {
      this.sliderInterval.unsubscribe();
    }
  }

  getCDNUrl(publicUrl) {
    // Why? because we dont have the companyId: https:/....net/[[companyId]]/[[fileId]]
    return publicUrl.replace('https://iascreenmanager.blob.core.windows.net/', environment.azureCDN);
  }

  returnFileExt(filename: string) {
    // get file extension
    const splitted = filename.split('.');
    return splitted[splitted.length - 1].toLowerCase();
  }

  pushNextSlide() {
    // stop if all slides are pushed
    if (this.currentMediaItems.length === this.mediaItems.length) {
      return;
    }
    // push next slide
    this.currentMediaItems.push(this.mediaItems[this.currentMediaItems.length]);
  }

  onPlayerEndSoon(event) {
    console.log('player end soon');
    if (!this.endSoonSent) {
      this.pushNextSlide();
      this.endSoonSent = true;
      this.makeSliderAnimation(null, 'next');
    }
  }

  onPlayerEnded() {
    console.log('player end');
    this.videoMediaItems = null;
    // skip video slide
    this.sliderIndex = this.getNextSliderIndex();
    const mediaItems = this.currentMediaItems;
    const fileExt = this.returnFileExt(mediaItems[this.sliderIndex].filename);
    if (fileExt === 'mp4') {
      this.makeVideoItems(mediaItems);
    } else {
      this.startSlider(this.currentMediaItems, 'video');
    }
  }

  close() {
    this.onClose.emit();
  }
}
