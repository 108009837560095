import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export class Location {
  locationId: string;
  personCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  location = new BehaviorSubject<Location>(new Location());

  itemDoc: any;
  item: any;

  constructor(
    private firestore: AngularFirestore,
    private analytics: AngularFireAnalytics
  ) { }

  subscribeToLocation(id) {
    this.itemDoc = this.firestore.doc<any>('locations/' + id);
    this.item = this.itemDoc.valueChanges().subscribe((datas) => {
      if (datas) {
        localStorage.setItem('location', JSON.stringify(datas));
        this.location.next(datas);
        this.analytics.logEvent('player_read_location_success', { uuid: id, locationId: id });
      } else {
        console.log("no location datas");
      }
    }, (err) => {
        this.analytics.logEvent('player_read_location_error', { uuid: id, error: err, locationId: id  });
        console.log('error on firebase location subscription : ', err);
    });

  }
}
