import { PriorityStrategy } from './priority-strategy.abstract';
import { Media } from '../models/media.class';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { RequestService } from '../request.service';
import { Inject } from '@angular/core';
import { MediaItem } from '../models/media-item.class';
import { ModuleService } from '../module.service';
import { MensaMaxRequestBody } from '../models/mensamax.class';
import { ModuleCustomConfig } from '../models/module-custom-config';
import { TimetableEntry } from '../types/timetable-entry.type';

export class ModulePriorityStrategy extends PriorityStrategy {
  readonly PRIORITY_NAME = 'module';
  private longpullingTimestamp = null;

  constructor(
    @Inject(RequestService) requestService,
    @Inject(ModuleService) moduleService
  ) {
    super(requestService, moduleService);
  }
  processing(): Media | null {
    // this is called every second
    const entries: TimetableEntry[] = this.timeTable[this.PRIORITY_NAME];
    const now: moment.Moment = moment().set({ date: 1, month: 0, year: 1900 });
    let intime = false;

    for (const entry of entries) {
      const start = this.convertDate(entry.startTime);
      const end = this.convertDate(entry.endTime);
      if (start.isBefore(now) && end.isAfter(now)) {
        // Init Weekday Check
        const config: ModuleCustomConfig = JSON.parse(entry.media.mediaItems[0].config);
        // Person Counter needs no additional checks
        if (this.timeTable.module[0].media.mediaTypeId === 'f8f87057-42e3-44a8-adee-a05be2776fae') { 
          return entry.media;
        }
        if (this.weekdayCheck(config)) {
          intime = true;
        }
      }
    }
    if (!intime) {
      return null;
    }

    // longpulling
    if (this.moduleService.longpullingTimestamp.value === null) {
      this.moduleService.longpullingTimestamp.next(new Date());
      let mediaObject = new Media();

      // Yoojis Module identification
      if (this.timeTable.module[0].media.mediaTypeId === 'b5911ab1-6e5d-44ac-a25e-7c401bea834f') {
        mediaObject = this.checkIfYoojisMediaPresent();
      }
      // MensaMax Module identification
      if (this.timeTable.module[0].media.mediaTypeId === 'acb8d1bf-1fb8-4076-8b54-079e95a051a5') {
        mediaObject = this.getMensaMaxData();
      }

      if (mediaObject && mediaObject.mediaId) {
        return mediaObject;
      }

    }
    const t = this.moduleService.longpullingTimestamp.value; // last longpull timestamp
    if (moment().isBefore(moment(t).add(15, 'minutes'))) {
      // The request has happened within the last 15 Minutes, dont do anything
    } else {
      console.log("Module Longpulling: Next Pull now:", moment());
      this.moduleService.longpullingTimestamp.next(null);
    }


    // Yoojis
    if (this.timeTable.module[0].media.mediaTypeId === 'b5911ab1-6e5d-44ac-a25e-7c401bea834f') {
      if (this.moduleService.moduleMediaItems.value.length > 0) {
        return this.timeTable.module[0].media;
      } else {
        return null;
      }
    }
    // MensaMax
    if (this.timeTable.module[0].media.mediaTypeId === 'acb8d1bf-1fb8-4076-8b54-079e95a051a5') {
      if (this.moduleService.mensaMaxData.value) {
        return this.timeTable.module[0].media;
      } else {
        return null;
      }
    }

    // if we are here, we return null to try resolve media in next tick
    return null;
  }

  weekdayCheck(config: ModuleCustomConfig): boolean {
    let inTime = false;
    if (!config.weekdays) {
      inTime = true;
      return inTime;
    }
    config.weekdays.forEach((w) => {
      if (w.id === moment().isoWeekday() && w.value === true) {
        inTime = true;
      }
    });
    return inTime;
  }

  getMensaMaxData() {
    const config = this.timeTable.module[0].media.mediaItems[0].config;
    const jsonObj: ModuleCustomConfig = JSON.parse(config);
    if (this.moduleService.isRequesting.value === true) {
      return null;
    }
    if (this.moduleService.isRequesting.value === false && this.moduleService.mensaMaxData.value) {
      return this.timeTable.module[0].media;
    }
    this.moduleService.isRequesting.next(true);
    const body: MensaMaxRequestBody = {
      MenueplanID: jsonObj.menuplanId.toString(),
      Von: moment().format('YYYY-MM-DDTHH:mm:ss'),
      Bis: moment().set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DDTHH:mm:ss')
    };
    this.requestService.getMensaMaxMenu(body).subscribe(res => {
      if (res === null) {
        return null;
      }
      this.moduleService.mensaMaxData.next(res);
      this.moduleService.isRequesting.next(false);
    }, err => {
      // Mensa Max Request error (TODO)
      this.moduleService.mensaMaxData.next(null);
      this.moduleService.isRequesting.next(false);
      return null;
    });
  }

  checkIfYoojisMediaPresent(): any {
    const config = this.timeTable.module[0].media.mediaItems[0].config;
    const jsonObj: ModuleCustomConfig = JSON.parse(config);
    if (this.moduleService.isRequesting.value === true) {
      return null;
    }
    // if (this.moduleService.isRequesting.value === false && this.moduleService.moduleMediaItems.value.length > 0) {
    //   console.log("we done here")
    //   return this.timeTable.module[0].media;
    // }
    this.moduleService.isRequesting.next(true);
    this.requestService.checkIfYoojisPromoPresent(jsonObj.restaurantId).subscribe(res => {
      if (res === null) {
        return null;
      } else {
        for (let i = 0; i < res.length; i++) {
          if (i === 0) {
            this.moduleService.moduleMediaItems.next([]);
          }
          const element = res[i];
          const payload = {
            overlaykey: 'yoojis_' + element.promotionType,
            picturepath: 'https://yooimage.azurewebsites.net/media'
              + '/' + element.screenLandscapeImageUrl + '?quality=90&format=jpeg&mode=max&width=1920',
            nocache: false
          };
          this.requestService.postYoojisMediaIaffmpeg(payload).subscribe(res2 => {
            const mediaItem = new MediaItem();
            mediaItem.companyId = this.timeTable.module[0].media.companyId;
            mediaItem.config = this.timeTable.module[0].media.mediaItems[0].config;
            mediaItem.filename = res2.videourl.replace('/videos/', '');
            mediaItem.mediaId = this.timeTable.module[0].media.mediaId;
            mediaItem.mediaItemId = null; // Welche ID ist das?
            mediaItem.publicUrl = environment.iaffmpegBaseUrl + res2.videourl;
            mediaItem.showSeconds = null; // Was soll hier rein
            mediaItem.sortNr = 1; // Was soll hier rein
            const moduleArray = this.moduleService.moduleMediaItems.value;
            moduleArray.push(mediaItem);
            this.moduleService.moduleMediaItems.next(moduleArray);
            if (this.moduleService.moduleMediaItems.value.length === res.length) {
              this.moduleService.isRequesting.next(false);
            }
          }, err => {
            console.log(err);
          }
          );
        }
      }
    }, err => {
      console.log(err);
    });
  }
}
