import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LocationService, Location } from 'src/app/location.service';
import { ScreenService } from 'src/app/screen.service';
import { Media } from 'src/app/player-v2/models/media.class';

@Component({
  selector: 'app-person-counter',
  templateUrl: './person-counter.component.html',
  styleUrls: ['./person-counter.component.scss']
})
export class PersonCounterComponent implements OnInit, OnDestroy {

  location: Location;
  locationSub: any;
  maxPersonsAmount = 8;

  missingLocationId = false;

  @Input() currentMedia: Media;


  constructor(
    private locationService: LocationService,
    private screenService: ScreenService
  ) { }

  ngOnInit() {
    console.log(this.currentMedia);
    if (this.currentMedia) {
      if (this.currentMedia.mediaItems) {
        const config = JSON.parse(this.currentMedia.mediaItems[0].config);
        console.log(config.maxPersonsAmount);
        this.maxPersonsAmount = config.maxPersonsAmount;
      }
    }
    const locationId = this.screenService.screen.value.locationId;
    console.log(locationId);
    if (locationId) {
      this.locationSub = this.locationService.location.subscribe((location) => {
        console.log(location);
        this.location = location;
      });
      this.locationService.subscribeToLocation(locationId);
    } else {
      this.missingLocationId = true;
    }   
  }

  ngOnDestroy() {

  }

}
